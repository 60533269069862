import React from 'react';
import '../styles/LoadingSquare.css';

const LoadingSquare: React.FC = () => {
  return (
    <div className="loading-square-container">
      <div className="loading-square">
        <div className="loading-square-inner"></div>
      </div>
    </div>
  );
};

export default LoadingSquare;