// src/components/withAuthRedirect.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import LoadingSquare from './LoadingSquare';

const withAuthRedirect = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: P) => {
    const navigate = useNavigate();
    const [isChecking, setIsChecking] = useState(true);

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          navigate('/');
        }
        setIsChecking(false);
      });

      return () => unsubscribe();
    }, [navigate]);

    if (isChecking) {
      return <LoadingSquare />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuthRedirect;
