// src/components/PhoneLoginForm.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth, db } from '../firebaseConfig';
import { setDoc, doc } from 'firebase/firestore';
import '../styles/auth-forms.css';
import ResetPasswordPopup from './ResetPasswordPopup';

const PhoneLoginForm: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [popupMessage, setPopupMessage] = useState('');
  const [isVerificationSent, setIsVerificationSent] = useState(false);

  const navigate = useNavigate();

  const setupRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
        'callback': () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      });
    }
  };

  const handleSendVerificationCode = async () => {
    setPopupMessage('');
    try {
      setupRecaptcha();
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
      setConfirmationResult(confirmation);
      setIsVerificationSent(true);
      setPopupMessage('Код верификации отправлен на ваш номер.');
    } catch (error: any) {
      console.error('Error sending verification code:', error.message);
      setPopupMessage('Ошибка при отправке кода верификации. Попробуйте еще раз.');
    }
  };

  const handleVerifyCode = async () => {
    setPopupMessage('');
    try {
      const userCredential = await confirmationResult.confirm(verificationCode);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        uid: user.uid,
        phoneNumber: user.phoneNumber,
      });

      const token = await user.getIdToken();
      localStorage.setItem('authToken', token);

      navigate('/');
    } catch (error: any) {
      console.error('Error verifying code:', error.message);
      setPopupMessage('Неверный код верификации. Попробуйте еще раз.');
    }
  };

  return (
    <div className="page-container">
      <div className="container">
        <div className="form-container">
          <div className="tab-switch">
          </div>
          <h2 className="tab-button">
            {isVerificationSent ? 'Введите код верификации' : 'Вход по номеру телефона'}
          </h2>
          {!isVerificationSent ? (
            <>
              <input
                type="tel"
                placeholder="Номер телефона"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="input-field"
              />
              <button className="form-button" onClick={handleSendVerificationCode}>
                Отправить код
              </button>
            </>
          ) : (
            <>
              <input
                type="text"
                placeholder="Код верификации"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="input-field"
              />
              <button className="form-button" onClick={handleVerifyCode}>
                Подтвердить
              </button>
            </>
          )}
          <button className="form-button" onClick={() => navigate(-1)}>Назад</button>
        </div>
      </div>
      <div id="recaptcha-container"></div>
      {popupMessage && (
        <ResetPasswordPopup 
          message={popupMessage} 
          onClose={() => setPopupMessage('')} 
        />
      )}
    </div>
  );
};

export default PhoneLoginForm;
