import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import SimpleButton from '../components/SimpleButton';
import '../styles/styles.css';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, []);

  const handleClick = (courseName: string) => {
    if (courseName.toLowerCase() === 'login') {
      if (isAuthenticated) {
        navigate('/account');
      } else {
        navigate('/login');
      }
    } else {
      navigate(`/${courseName.toLowerCase()}`);
    }
  };

  const buttons = [
    { name: "Языки программирования", onClick: () => handleClick("lang") },
    { name: "Мобильная разработка", onClick: () => handleClick("Mobile") },
    { name: "Технологии и инструменты", onClick: () => handleClick("Tech") },
    { name: "Алгоритмы и структуры данных", onClick: () => handleClick("Algo") },
    { name: "Архитектура и дизайн", onClick: () => handleClick("Design") },
    { name: "Безопасность", onClick: () => handleClick("Sec") },
    { name: "Наблюдаемость", onClick: () => handleClick("Log") },
    { name: "QA", onClick: () => handleClick("Test") },
    { name: "Data Science", onClick: () => handleClick("DS") },
    { name: "Нейросети", onClick: () => handleClick("AI") },
    { name: "Вопросы из собеседований", onClick: () => handleClick("HR") },
    { name: "Soft Skills", onClick: () => handleClick("Skills") },
    { name: "Дополнительные разделы", onClick: () => handleClick("Abv") },
    { name: "Аккаунт", onClick: () => handleClick("Login") },
  ];

  return (
    <div className="page-container">
      <div className="container">
        <div className="button-container">
          {buttons.map((button, index) => (
            <SimpleButton key={index} text={button.name} onClick={button.onClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;