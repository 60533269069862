import React from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../components/SimpleButton';
import '../../styles/styles.css';

const LangPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    if (path === 'back') {
      navigate(-1);
    } else {
      navigate(`/${path}`);
    }
  };

  const levels = [
    { name: "TypeScript", onClick: () => handleClick('ts') },
    { name: "Go", onClick: () => handleClick('go') },
    { name: "Python", onClick: () => handleClick('python') },
    { name: "Java", onClick: () => handleClick('java') },
    { name: "C#", onClick: () => handleClick('c') },
    { name: "Rust", onClick: () => handleClick('rust') },
    { name: "Назад", onClick: () => handleClick('back') },
  ];

  return (
    <div className="page-container">
      <div className="container">
        <div className="button-container">
          {levels.map((level, index) => (
            <SimpleButton key={index} text={level.name} onClick={level.onClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LangPage;
