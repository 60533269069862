import React, { useEffect, useState } from 'react';
import '../styles/AuthPopup.css';

interface ResetPasswordPopupProps {
  message: string;
  onClose: () => void;
}

const ResetPasswordPopup: React.FC<ResetPasswordPopupProps> = ({ message, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    const closeTimer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(onClose, 300); // Вызываем onClose после завершения анимации
    }, 5000);

    return () => {
      clearTimeout(timer);
      clearTimeout(closeTimer);
    };
  }, [onClose]);

  return (
    <div className={`auth-popup ${isVisible ? 'visible' : ''}`}>
      <div className="auth-popup-content">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default ResetPasswordPopup;