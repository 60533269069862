import React from 'react';
import '../styles/styles.css';

interface SimpleButtonProps {
  text: string;
  onClick: () => void;
}

const SimpleButton: React.FC<SimpleButtonProps> = ({ text, onClick }) => {
  return (
    <button className="simple-button" onClick={onClick}>
      {text}
    </button>
  );
};

export default SimpleButton;
