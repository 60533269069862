// src/components/AccountPage.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import '../styles/styles.css';
import { auth, db } from '../firebaseConfig';
import AuthPopup from './AuthPopup';
import SubscriptionPopup from './SubscriptionPopup';

const AccountPage: React.FC = () => {
  const navigate = useNavigate();
  const [authInfo, setAuthInfo] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [subscriptionLevel, setSubscriptionLevel] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setAuthInfo(user.email || user.phoneNumber || '');
        setIsAuthenticated(true);

        // Добавляем задержку перед проверкой уровня подписки
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const subscription = userData.subscription;
            if (subscription) {
              if (subscription.ai) {
                setSubscriptionLevel('Нейросети');
              } else if (subscription.libraries) {
                setSubscriptionLevel('Библиотеки');
              } else if (subscription.advanced) {
                setSubscriptionLevel('Продвинутый');
              } else {
                setSubscriptionLevel('Базовый');
              }
            } else {
              setSubscriptionLevel('Базовый');
            }
          }

      } else {
        setIsAuthenticated(false);
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleAccount = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="page-container">
      {isAuthenticated && <AuthPopup info={authInfo} />}
      {isAuthenticated && subscriptionLevel && <SubscriptionPopup level={subscriptionLevel} />}
      <div className="container">
        <div className="button-container">
          <button className="simple-button" onClick={handleAccount}>Выход из аккаунта</button>
          <button className="simple-button" onClick={() => navigate('/subscription')}>Подписка</button>
          <button className="simple-button" onClick={() => navigate(-1)}>Назад</button>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
