// GoogleLogin.ts
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';

export const handleGoogleLogin = async (navigate: (path: string) => void) => {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    if (!user) {
      throw new Error('Пользователь не найден');
    }
    // Сохранение данных пользователя в Firestore
    await setDoc(doc(db, 'users', user.uid), {
      uid: user.uid,
      email: user.email,
      // Добавьте другие данные пользователя, если необходимо
    }, { merge: true });
    
    // Перенаправление пользователя после успешной авторизации
    navigate('/');
  } catch (error) {
    console.error('Error during Google sign-in:', error);
  }
};