// src/components/SubscriptionPopup.tsx
import React, { useEffect, useState } from 'react';
import '../styles/AuthPopup.css';

interface SubscriptionPopupProps {
  level: string;
}

const SubscriptionPopup: React.FC<SubscriptionPopupProps> = ({ level }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 300); // Показываем через 1.5 секунды после показа AuthPopup

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`subscription-popup ${isVisible ? 'visible' : ''}`}>
      <div className="subscription-popup-content">
        <p>Ваш уровень подписки: {level}</p>
      </div>
    </div>
  );
};

export default SubscriptionPopup;
