import { useState, useEffect } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebaseConfig';

const useFirebaseStorage = (filePath: string) => {
  const [content, setContent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(`Starting to fetch file from path: ${filePath}`);
        const fileRef = ref(storage, filePath);
        console.log('File reference created');
        
        const url = await getDownloadURL(fileRef);
        console.log(`File URL obtained: ${url}`);
        
        const response = await fetch(url, {
          headers: {
            'Origin': 'https://hdigitallab.ru'
          }
        });
        console.log(`Fetch response status: ${response.status}`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const text = await response.text();
        console.log(`Content length: ${text.length} characters`);
        setContent(text);
        console.log('Content set successfully');
      } catch (err) {
        console.error('Error in useFirebaseStorage:', err);
        setError(`Ошибка при загрузке контента: ${err instanceof Error ? err.message : String(err)}`);
      } finally {
        setLoading(false);
        console.log('Loading set to false');
      }
    };

    fetchData();
  }, [filePath]);

  return { content, loading, error };
};

export default useFirebaseStorage;