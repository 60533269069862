// src/components/AuthPopup.tsx
import React, { useEffect, useState } from 'react';
import '../styles/AuthPopup.css';

interface AuthPopupProps {
  info: string;
}

const AuthPopup: React.FC<AuthPopupProps> = ({ info }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`auth-popup ${isVisible ? 'visible' : ''}`}>
      <div className="auth-popup-content">
        <p>Вы авторизованы как {info}</p>
      </div>
    </div>
  );
};

export default AuthPopup;
