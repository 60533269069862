import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/auth-forms.css';

const subscriptionLevels = [
  { name: 'Продвинутый', month: 1600, halfYear: 6700, year: 9500 },
  { name: 'Библиотеки', month: 3800, halfYear: 19800, year: 29300 },
  { name: 'Нейросети', month: 6900, halfYear: 29400, year: 49700 },
];

const periods = ['Месяц', 'Полгода', 'Год'];

const SubscriptionPage: React.FC = () => {
  const navigate = useNavigate();
  const [selectedLevel, setSelectedLevel] = useState(subscriptionLevels[0]);
  const [selectedPeriod, setSelectedPeriod] = useState('Месяц');

  const getPrice = (level: typeof subscriptionLevels[0], period: string) => {
    switch (period) {
      case 'Месяц': return level.month;
      case 'Полгода': return level.halfYear;
      case 'Год': return level.year;
      default: return 0;
    }
  };

  return (
    <div className="page-container">
      <div className="container">
        <div className="form-container">
          <div className="text-sm mb-4">
            Скидка: Полгода ~20%, Год ~40%
          </div>
          <div className="tab-switch">
            {subscriptionLevels.slice(0, 2).map((level) => (
              <button
                key={level.name}
                className={`tab-button ${selectedLevel.name === level.name ? 'active' : ''}`}
                onClick={() => setSelectedLevel(level)}
              >
                {level.name}
              </button>
            ))}
          </div>
          <div className="tab-switch mb-4">
            <button
              className={`tab-button w-full ${selectedLevel.name === 'Нейросети' ? 'active' : ''}`}
              onClick={() => setSelectedLevel(subscriptionLevels[2])}
            >
              Нейросети
            </button>
          </div>
          <div className="w-full mb-4">
            <select
              className="input-field"
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value)}
            >
              {periods.map((period) => (
                <option key={period} value={period}>{period}</option>
              ))}
            </select>
          </div>
          <div className="text-xl mb-4">
            Цена: {getPrice(selectedLevel, selectedPeriod)} ₽
          </div>
          <button className="form-button">Оформить подписку</button>
        </div>
        <button className="simple-button mt-6 w-48 h-12" onClick={() => navigate(-1)}>
          Назад
        </button>
      </div>
    </div>
  );
};

export default SubscriptionPage;