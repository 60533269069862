import React from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../components/SimpleButton';
import '../../styles/styles.css';

const BasicStructuresPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(`/${path}`);
};

  const levels = [
    { name: "Массивы", onClick: () => handleClick('algo/basic_structures/arrays') },
    { name: "Связанные списки", onClick: () => handleClick('algo/basic_structures/linked_lists') },
    { name: "Деревья", onClick: () => handleClick('algo/basic_structures/trees') },
    { name: "Графы", onClick: () => handleClick('algo/basic_structures/graphs') },
    { name: "Кучи", onClick: () => handleClick('algo/basic_structures/heaps') },
    { name: "Хеш-таблицы", onClick: () => handleClick('algo/basic_structures/hash_tables') },
    { name: "Декартовы кучи", onClick: () => handleClick('algo/basic_structures/treap') },
    { name: "Суффиксные деревья", onClick: () => handleClick('algo/basic_structures/suffix_trees') },
    { name: "Суффиксные массивы", onClick: () => handleClick('algo/basic_structures/suffix_arrays') },
    { name: "Дек (двусторонняя очередь)", onClick: () => handleClick('algo/basic_structures/deque') },
    { name: "Очереди с приоритетами", onClick: () => handleClick('algo/basic_structures/priority_queues') },
    { name: "Тритовые деревья", onClick: () => handleClick('algo/basic_structures/trie') },
    { name: "B-деревья и B+ деревья", onClick: () => handleClick('algo/basic_structures/b_trees') },
    { name: "Skip-списки", onClick: () => handleClick('algo/basic_structures/skip_lists') },
    { name: "Назад", onClick: () => handleClick('algo/') },
  ];
  
  return (
    <div className="page-container">
      <div className="container">
        <div className="button-container">
          {levels.map((level, index) => (
            <SimpleButton key={index} text={level.name} onClick={level.onClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BasicStructuresPage;
