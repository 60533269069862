import React from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../../components/SimpleButton';
import '../../../styles/styles.css';

const DesignPatternsPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
      navigate(`/${path}`);
  };
  
  const levels = [
    { name: "Overview", onClick: () => handleClick('design/design_patterns/overview') },
    { name: "Порождающие шаблоны", onClick: () => handleClick('design/design_patterns/generating_patterns') },
    { name: "Структурные шаблоны", onClick: () => handleClick('design/design_patterns/structural_patterns') },
    { name: "Поведенческие шаблоны", onClick: () => handleClick('design/design_patterns/behavioural_patterns') },
    { name: "Назад", onClick: () => handleClick('design/') },
  ];
  
  return (
    <div className="page-container">
      <div className="container">
        <div className="button-container">
          {levels.map((level, index) => (
            <SimpleButton key={index} text={level.name} onClick={level.onClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DesignPatternsPage;
