import React from 'react';
import MarkdownPage from '../../../components/MarkdownPage';

const DesignPatternsOverviewPage: React.FC = () => {
  console.log('Rendering DesignPatternsOverviewPage');
  return (
    <MarkdownPage 
      filePath="design/design_design_patterns_overview.md" 
      backRoute="/design" 
      menuRoute="/design" 
      forwardRoute="/design/design_patterns/next" 
    />
  );
};

export default DesignPatternsOverviewPage;