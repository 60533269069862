import React from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../components/SimpleButton';
import '../../styles/styles.css';

const DesignPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
      navigate(`/${path}`);
  };
  
  const levels = [
    { name: "Шаблоны проектирования", onClick: () => handleClick('design/design_patterns') },
    { name: "SOLID принципы", onClick: () => handleClick('design/solid_principles') },
    { name: "Clean Architecture", onClick: () => handleClick('design/clean_architecture') },
    { name: "Domain-Driven Design (DDD)", onClick: () => handleClick('design/ddd') },
    { name: "Event-Driven Architecture (EDA)", onClick: () => handleClick('design/eda') },
    { name: "Микросервисы", onClick: () => handleClick('design/microservices') },
    { name: "Монолитная vs. распределенная архитектура", onClick: () => handleClick('design/monolithic_vs_distributed_architecture') },
    { name: "Serverless Architecture", onClick: () => handleClick('design/serverless_architecture') },
    { name: "Назад", onClick: () => handleClick('../') },
  ];
  
  return (
    <div className="page-container">
      <div className="container">
        <div className="button-container">
          {levels.map((level, index) => (
            <SimpleButton key={index} text={level.name} onClick={level.onClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DesignPage;
