// src/pages/MarkdownPage.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/styles.css';
import '../styles/prism.css';
import '../styles/markdown-content.css';
import MarkdownContent from './MarkdownContent';

interface MarkdownPageProps {
  filePath: string;
  backRoute: string;
  menuRoute: string;
  forwardRoute: string;
}

const MarkdownPage: React.FC<MarkdownPageProps> = ({ filePath, backRoute, menuRoute, forwardRoute }) => {
  const navigate = useNavigate();

  const handleBack = () => navigate(backRoute);
  const handleMenu = () => navigate(menuRoute);
  const handleForward = () => navigate(forwardRoute);

  return (
    <div className="page-container">
      <div className="markdown-wrapper">
        <div className="button-group top">
          <button className="custom-button back" onClick={handleBack}>
            Назад
          </button>
          <button className="custom-button menu" onClick={handleMenu}>
            Меню
          </button>
          <button className="custom-button forward" onClick={handleForward}>
            Вперед
          </button>
        </div>
        <MarkdownContent filePath={filePath} />
        <div className="button-group bottom">
          <button className="custom-button back" onClick={handleBack}>
            Назад
          </button>
          <button className="custom-button menu" onClick={handleMenu}>
            Меню
          </button>
          <button className="custom-button forward" onClick={handleForward}>
            Вперед
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarkdownPage;
