import React from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../components/SimpleButton';
import '../../styles/styles.css';

const AdvancedAlgorithmsStructuresPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
      navigate(`/${path}`);
  };

  const levels = [
    { name: "Сбалансированные деревья", onClick: () => handleClick('algo/advanced_algorithms_and_structures/balanced_trees') },
    { name: "Фибоначчиевы кучи", onClick: () => handleClick('algo/advanced_algorithms_and_structures/fibonacci_heaps') },
    { name: "Алгоритмы для больших данных", onClick: () => handleClick('algo/advanced_algorithms_and_structures/big_data_algorithms') },
    { name: "Тритовые деревья", onClick: () => handleClick('algo/advanced_algorithms_and_structures/trie_trees') },
    { name: "Хеширование и хеш-функции", onClick: () => handleClick('algo/advanced_algorithms_and_structures/hashing') },
    { name: "Алгоритмы строки", onClick: () => handleClick('algo/advanced_algorithms_and_structures/string_algorithms') },
    { name: "Алгоритмы оптимизации", onClick: () => handleClick('algo/advanced_algorithms_and_structures/optimization_algorithms') },
    { name: "Геометрические алгоритмы", onClick: () => handleClick('algo/advanced_algorithms_and_structures/geometric_algorithms') },
    { name: "Вероятностные алгоритмы", onClick: () => handleClick('algo/advanced_algorithms_and_structures/probabilistic_algorithms') },
    { name: "Теория сложности", onClick: () => handleClick('algo/advanced_algorithms_and_structures/complexity_theory') },
    { name: "Назад", onClick: () => handleClick('algo/') },
  ];


  return (
    <div className="page-container">
      <div className="container">
        <div className="button-container">
          {levels.map((level, index) => (
            <SimpleButton key={index} text={level.name} onClick={level.onClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdvancedAlgorithmsStructuresPage;
