import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/styles.css';
import { handleGoogleLogin } from './GoogleLogin';
import withAuthRedirect from './withAuthRedirect';

const Login: React.FC = () => {
  const navigate = useNavigate();

  const handleEmailLogin = () => {
    navigate('/login/email-login');
  };

  const handlePhoneLogin = () => {
    navigate('/login/phone-login');
  };

  return (
    <div className="page-container">
      <div className="container">
        <div className="button-container">
          <button className="simple-button" onClick={handleEmailLogin}>Email</button>
          <button className="simple-button" onClick={handlePhoneLogin}>Phone</button>
          <button className="simple-button" onClick={() => handleGoogleLogin(navigate)}>Google</button>
          <button className="simple-button" onClick={() => navigate(-1)}>Назад</button>
        </div>
      </div>
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default withAuthRedirect(Login);
