// src/components/MarkdownContent.tsx
import React from 'react';
import ReactMarkdown from 'react-markdown';
import useFirebaseStorage from '../hooks/useFirebaseStorage';
import '../styles/styles.css';
import '../styles/prism.css';
import '../styles/markdown-content.css';
import LoadingSquare from './LoadingSquare'; // Импортируем компонент загрузки
import { renderers } from './MarkdownRenderers';

const MarkdownContent: React.FC<{ filePath: string }> = ({ filePath }) => {
  const { content, loading, error } = useFirebaseStorage(filePath);

  if (loading) {
    return <LoadingSquare />; // Отображаем компонент загрузки
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="markdown-content">
      <ReactMarkdown children={content} components={renderers} />
    </div>
  );
};

export default MarkdownContent;
