// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import './styles/styles.css';
import Login from './components/Login';
import EmailLoginForm from './components/EmailLoginForm';
import PasswordReset from './components/PasswordReset';
import PhoneLoginForm from './components/PhoneLoginForm';
import withAuthRedirect from './components/withAuthRedirect';
import LoadingSquare from './components/LoadingSquare';
import AccountPage from './components/AccountPage';
import SubscriptionPage from './components/SubscriptionPage';
import LangPage from './pages/lang/LangPage';
import CPage from './pages/lang/CPage';
import GoPage from './pages/lang/GoPage';
import JavaPage from './pages/lang/JavaPage';
import PythonPage from './pages/lang/PythonPage';
import RustPage from './pages/lang/RustPage';
import TSPage from './pages/lang/TSPage';
import AlgoPage from './pages/algo/AlgoPage';
import BasicStructuresPage from './pages/algo/BasicStructuresPage';
import BasicAlgorithmsPage from './pages/algo/BasicAlgorithmsPage';
import AdvancedAlgorithmsStructuresPage from './pages/algo/AdvancedAlgorithmsStructuresPage';
import DesignPage from './pages/design/DesignPage';
import GeneratingPatternsPage from './pages/design/DesignPatterns/GeneratingPatternsPage';
import DesignPatternsPage from './pages/design/DesignPatterns/DesignPatternsPage';
import DesignPatternsOverviewPage from './pages/design/DesignPatterns/DesignPatternsOverviewPage';
import MarkdownPage from './components/MarkdownPage';

const ProtectedLogin = withAuthRedirect(Login);
const ProtectedEmailLoginForm = withAuthRedirect(EmailLoginForm);
const ProtectedPhoneLoginForm = withAuthRedirect(PhoneLoginForm);
const ProtectedPasswordReset = withAuthRedirect(PasswordReset);

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="login" element={<ProtectedLogin />} />
        <Route path="login/email-login" element={<ProtectedEmailLoginForm />} />
        <Route path="login/phone-login" element={<ProtectedPhoneLoginForm />} />
        <Route path="loading" element={<LoadingSquare />} />
        <Route path="Account" element={<AccountPage />} />
        <Route path="password-reset" element={<ProtectedPasswordReset />} />
        <Route path="subscription" element={<SubscriptionPage />} />
        <Route path="lang" element={<LangPage />} />
        <Route path="c" element={<CPage />} />
        <Route path="c/basic" element={<CPage />} />
        <Route path="c/advanced" element={<CPage />} />
        <Route path="c/libraries" element={<CPage />} />
        <Route path="go" element={<GoPage />} />
        <Route path="go/basic" element={<GoPage />} />
        <Route path="go/advanced" element={<GoPage />} />
        <Route path="go/libraries" element={<GoPage />} />
        <Route path="java" element={<JavaPage />} />
        <Route path="java/basic" element={<JavaPage />} />
        <Route path="java/advanced" element={<JavaPage />} />
        <Route path="java/libraries" element={<JavaPage />} />
        <Route path="rust" element={<RustPage />} />
        <Route path="rust/basic" element={<RustPage />} />
        <Route path="rust/advanced" element={<RustPage />} />
        <Route path="rust/libraries" element={<RustPage />} />
        <Route path="python" element={<PythonPage />} />
        <Route path="python/advanced" element={<PythonPage />} />
        <Route path="python/libraries" element={<PythonPage />} />
        <Route path="ts" element={<TSPage />} />
        <Route path="ts/basic" element={<TSPage />} />
        <Route path="ts/advanced" element={<TSPage />} />
        <Route path="ts/libraries" element={<TSPage />} />
        <Route path="design" element={<DesignPage />} />
        <Route path="design/design_patterns" element={<DesignPatternsPage />} />
        <Route path="/design/design_patterns/overview" element={<DesignPatternsOverviewPage />} />      
        <Route path="/design/design_patterns/generating_patterns" element={<GeneratingPatternsPage />} />

        <Route path="/design/design_patterns/generating_patterns/overview" element={<MarkdownPage 
        filePath="design/design_design_patterns_generating_patterns_overview.md"
        menuRoute="/design/design_patterns/generating_patterns" 
        backRoute="/design/design_patterns/generating_patterns" 
        forwardRoute="/design/design_patterns/generating_patterns/singleton" />} />

        <Route path="/design/design_patterns/generating_patterns/singleton" element={<MarkdownPage 
        filePath="design/design_design_patterns_generating_patterns_singleton.md" 
        menuRoute="/design/design_patterns/generating_patterns" 
        backRoute="/design/design_patterns/generating_patterns/overview"
        forwardRoute="/design/design_patterns/generating_patterns/factory_method" />} />

        <Route path="/design/design_patterns/generating_patterns/factory_method" element={<MarkdownPage 
        filePath="design/design_design_patterns_generating_patterns_factory_method.md" 
        menuRoute="/design/design_patterns/generating_patterns" 
        backRoute="/design/design_patterns/generating_patterns/singleton" 
        forwardRoute="/design/design_patterns/generating_patterns/abstract_factory" />} />

        <Route path="/design/design_patterns/generating_patterns/abstract_factory" element={<MarkdownPage 
        filePath="design/design_design_patterns_generating_patterns_abstract_factory.md" 
        menuRoute="/design/design_patterns/generating_patterns" 
        backRoute="/design/design_patterns/generating_patterns/factory_method" 
        forwardRoute="design/design_patterns/generating_patterns/builder" />} />

        <Route path="/design/design_patterns/generating_patterns/builder" element={<MarkdownPage 
        filePath="design/design_design_patterns_generating_patterns_builder.md" 
        menuRoute="/design/design_patterns/generating_patterns" 
        backRoute="/design/design_patterns/generating_patterns/abstract_factory" 
        forwardRoute="/design/design_patterns/generating_patterns/prototype" />} />

        <Route path="/design/design_patterns/generating_patterns/prototype" element={<MarkdownPage 
        filePath="design/design_design_patterns_generating_patterns_prototype.md" 
        menuRoute="/design/design_patterns/generating_patterns" 
        backRoute="/design/design_patterns/generating_patterns/builder" 
        forwardRoute="design/design_patterns/structural_patterns/" />} />

        <Route path="design/design_patterns/structural_patterns" element={<DesignPatternsPage />} />
        <Route path="design/design_patterns/behavioural_patterns" element={<DesignPatternsPage />} /> 
        <Route path="design/solid_principles" element={<DesignPage />} />
        <Route path="design/clean_architecture" element={<DesignPage />} />
        <Route path="design/ddd" element={<DesignPage />} />
        <Route path="design/eda" element={<DesignPage />} />
        <Route path="design/microservices" element={<DesignPage />} />
        <Route path="design/monolithic_vs_distributed_architecture" element={<DesignPage />} />
        <Route path="design/serverless_architecture" element={<DesignPage />} />
        <Route path="algo" element={<AlgoPage />} />
        <Route path="algo/basic_structures" element={<BasicStructuresPage />} />
        <Route path="algo/basic_algorithms" element={<BasicAlgorithmsPage />} />
        <Route path="algo/advanced_algorithms_and_structures" element={<AdvancedAlgorithmsStructuresPage />} />
        <Route path="algo/basic_structures/arrays" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/linked_lists" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/trees" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/graphs" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/heaps" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/hash_tables" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/treap" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/suffix_trees" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/suffix_arrays" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/deque" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/priority_queues" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/trie" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/b_trees" element={<BasicStructuresPage />} />
        <Route path="algo/basic_structures/skip_lists" element={<BasicStructuresPage />} />
        <Route path="algo/basic_algorithms/search_algorithms" element={<BasicAlgorithmsPage />} />
        <Route path="algo/basic_algorithms/sorting_algorithms" element={<BasicAlgorithmsPage />} />
        <Route path="algo/basic_algorithms/dynamic_programming" element={<BasicAlgorithmsPage />} />
        <Route path="algo/basic_algorithms/greedy_algorithms" element={<BasicAlgorithmsPage />} />
        <Route path="algo/basic_algorithms/divide_and_conquer" element={<BasicAlgorithmsPage />} />
        <Route path="algo/basic_algorithms/backtracking" element={<BasicAlgorithmsPage />} />
        <Route path="algo/basic_algorithms/graph_algorithms" element={<BasicAlgorithmsPage />} />
        <Route path="algo/basic_algorithms/streaming_algorithms" element={<BasicAlgorithmsPage />} />
        <Route path="algo/advanced_algorithms_and_structures/balanced_trees" element={<AdvancedAlgorithmsStructuresPage />} />
        <Route path="algo/advanced_algorithms_and_structures/fibonacci_heaps" element={<AdvancedAlgorithmsStructuresPage />} />
        <Route path="algo/advanced_algorithms_and_structures/big_data_algorithms" element={<AdvancedAlgorithmsStructuresPage />} />
        <Route path="algo/advanced_algorithms_and_structures/trie_trees" element={<AdvancedAlgorithmsStructuresPage />} />
        <Route path="algo/advanced_algorithms_and_structures/hashing" element={<AdvancedAlgorithmsStructuresPage />} />
        <Route path="algo/advanced_algorithms_and_structures/string_algorithms" element={<AdvancedAlgorithmsStructuresPage />} />
        <Route path="algo/advanced_algorithms_and_structures/optimization_algorithms" element={<AdvancedAlgorithmsStructuresPage />} />
        <Route path="algo/advanced_algorithms_and_structures/geometric_algorithms" element={<AdvancedAlgorithmsStructuresPage />} />
        <Route path="algo/advanced_algorithms_and_structures/probabilistic_algorithms" element={<AdvancedAlgorithmsStructuresPage />} />
        <Route path="algo/advanced_algorithms_and_structures/complexity_theory" element={<AdvancedAlgorithmsStructuresPage />} />
      </Routes>
    </Router>
  );
};

export default App;
