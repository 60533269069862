import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAX9s6B9h5C7vCwooTHjK6mwWgyZxwBuFk",
    authDomain: "hdigitallab.firebaseapp.com",
    projectId: "hdigitallab",
    storageBucket: "hdigitallab.appspot.com",
    messagingSenderId: "777619376122",
    appId: "1:777619376122:web:b3c2a412a9df8401ce4643",
    measurementId: "G-0S9G2LFQ22"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };

