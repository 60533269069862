import React from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../../components/SimpleButton';
import '../../../styles/styles.css';

const DesignPatterns: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
      navigate(`/${path}`);
  };
  
  const levels = [
      { name: "Overview", onClick: () => handleClick('design/design_patterns/generating_patterns/overview') },
      { name: "Singleton", onClick: () => handleClick('design/design_patterns/generating_patterns/singleton') },
      { name: "Factory Method", onClick: () => handleClick('design/design_patterns/generating_patterns/factory_method') },
      { name: "Abstract Factory", onClick: () => handleClick('design/design_patterns/generating_patterns/abstract_factory') },
      { name: "Builder", onClick: () => handleClick('design/design_patterns/generating_patterns/builder') },
      { name: "Prototype", onClick: () => handleClick('design/design_patterns/generating_patterns/prototype') },
      { name: "Назад", onClick: () => handleClick('design/design_patterns/') },  
  ];
  
  return (
    <div className="page-container">
      <div className="container">
        <div className="button-container">
          {levels.map((level, index) => (
            <SimpleButton key={index} text={level.name} onClick={level.onClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DesignPatterns;
