import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth, db } from '../firebaseConfig';
import { setDoc, doc } from 'firebase/firestore';
import '../styles/auth-forms.css';
import ResetPasswordPopup from './ResetPasswordPopup';

const EmailLoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const navigate = useNavigate();

  const handleAuth = async (authFunction: typeof signInWithEmailAndPassword | typeof createUserWithEmailAndPassword) => {
    setPopupMessage('');
    try {
      const userCredential = await authFunction(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        uid: user.uid,
        email: user.email,
      });

      const token = await user.getIdToken();
      localStorage.setItem('authToken', token);

      navigate('/');
    } catch (error: any) {
      console.error(isRegistering ? 'Error registering:' : 'Error signing in:', error.message);
      if (error.code === 'auth/invalid-credential' || error.code === 'auth/wrong-password') {
        setPopupMessage('Неверный логин или пароль');
      } else if (error.code === 'auth/user-not-found') {
        setPopupMessage('Пользователь не найден');
      } else if (error.code === 'auth/email-already-in-use') {
        setPopupMessage('Этот email уже используется');
      } else {
        setPopupMessage('Произошла ошибка. Попробуйте еще раз.');
      }
    }
  };

  const handleLogin = () => handleAuth(signInWithEmailAndPassword);
  const handleRegister = () => handleAuth(createUserWithEmailAndPassword);

  const handleResetPassword = async () => {
    setPopupMessage('');
    try {
      await sendPasswordResetEmail(auth, email);
      setPopupMessage('Ссылка для сброса пароля отправлена на ваш email.');
    } catch (error: any) {
      console.error('Error sending password reset email:', error.message);
      if (error.code === 'auth/user-not-found') {
        setPopupMessage('Пользователь с таким email не найден');
      } else {
        setPopupMessage('Ошибка при отправке email для сброса пароля');
      }
    }
  };

  return (
    <div className="page-container">
      <div className="container">
        <div className="form-container">
          <div className="tab-switch">
            <button
              className={`tab-button ${!isRegistering && !isResettingPassword ? 'active' : ''}`}
              onClick={() => { setIsRegistering(false); setIsResettingPassword(false); }}
            >
              Вход
            </button>
            <button
              className={`tab-button ${isRegistering ? 'active' : ''}`}
              onClick={() => { setIsRegistering(true); setIsResettingPassword(false); }}
            >
              Регистрация
            </button>
          </div>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input-field"
          />
          {!isResettingPassword && (
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-field"
            />
          )}
          <button
              className={`tab-button ${isResettingPassword ? 'active' : ''}`}
              onClick={() => { setIsResettingPassword(true); setIsRegistering(false); }}
            >
              Сброс пароля
          </button>
          <div className="form-button-container">
            <button className="form-button" onClick={() => navigate(-1)}>Назад</button>
            {isResettingPassword ? (
              <button className="form-button" onClick={handleResetPassword}>Сбросить пароль</button>
            ) : (
              <button
                className="form-button"
                onClick={isRegistering ? handleRegister : handleLogin}
              >
                {isRegistering ? 'Зарегистрироваться' : 'Войти'}
              </button>
            )}
          </div>
        </div>
      </div>
      {popupMessage && (
        <ResetPasswordPopup 
          message={popupMessage} 
          onClose={() => setPopupMessage('')} 
        />
      )}
    </div>
  );
};

export default EmailLoginForm;