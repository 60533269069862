import React from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../components/SimpleButton';
import '../../styles/styles.css';

const BasicAlgorithmsPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(`/${path}`);
};

  const levels = [
    { name: "Алгоритмы поиска", onClick: () => handleClick('algo/basic_algorithms/search_algorithms') },
    { name: "Алгоритмы сортировки", onClick: () => handleClick('algo/basic_algorithms/sorting_algorithms') },
    { name: "Динамическое программирование", onClick: () => handleClick('algo/basic_algorithms/dynamic_programming') },
    { name: "Жадные алгоритмы", onClick: () => handleClick('algo/basic_algorithms/greedy_algorithms') },
    { name: "Алгоритмы деления и завоевания", onClick: () => handleClick('algo/basic_algorithms/divide_and_conquer') },
    { name: "Обратный ход (Backtracking)", onClick: () => handleClick('algo/basic_algorithms/backtracking') },
    { name: "Алгоритмы на графах", onClick: () => handleClick('algo/basic_algorithms/graph_algorithms') },
    { name: "Потоковые алгоритмы", onClick: () => handleClick('algo/basic_algorithms/streaming_algorithms') },
    { name: "Назад", onClick: () => handleClick('algo/') },
  ];
  

  return (
    <div className="page-container">
      <div className="container">
        <div className="button-container">
          {levels.map((level, index) => (
            <SimpleButton key={index} text={level.name} onClick={level.onClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BasicAlgorithmsPage;
