import React from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../components/SimpleButton';
import '../../styles/styles.css';

const JavaPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    if (path === 'back') {
      navigate(-1);
    } else {
      navigate(`/${path}`);
    }
  };

  const levels = [
    { name: "Базовый", onClick: () => handleClick('java/basic') },
    { name: "Продвинутый", onClick: () => handleClick('java/advanced') },
    { name: "Библиотеки", onClick: () => handleClick('java/libraries') },
    { name: "Назад", onClick: () => handleClick('back') },
  ];

  return (
    <div className="page-container">
      <div className="container">
        <div className="button-container">
          {levels.map((level, index) => (
            <SimpleButton key={index} text={level.name} onClick={level.onClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default JavaPage;
