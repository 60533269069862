import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import '../styles/styles.css';

const PasswordReset: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Ссылка для сброса пароля отправлена на ваш email.');
    } catch (error) {
      setMessage('Ошибка при отправке email для сброса пароля.');
      console.error('Error sending password reset email:', error);
    }
  };

  return (
    <div className="page-container">
      <div className="container">
        <h2>Сброс пароля</h2>
        <form onSubmit={handleResetPassword}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Введите ваш email"
            required
          />
          <button type="submit" className="simple-button">Сбросить пароль</button>
        </form>
        {message && <p>{message}</p>}
        <button className="simple-button" onClick={() => navigate(-1)}>Назад</button>
      </div>
    </div>
  );
};

export default PasswordReset;